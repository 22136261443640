import M$plugin$PrinterMap from './/facade/js/printermap';
import M$control$PrinterMapControl from './/facade/js/printermapcontrol';
import M$impl$control$PrinterMapControl from './/impl/ol/js/printermapcontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.PrinterMap = M$plugin$PrinterMap;
window.M.control.PrinterMapControl = M$control$PrinterMapControl;
window.M.impl.control.PrinterMapControl = M$impl$control$PrinterMapControl;
